import React,{Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { API_ROOT } from './../api-config';
import Pagination from './Pagination';
class Modellist extends Component
{

    constructor(props){
    super(props);
    this.state={contents: [],activePage: 1};
        this.onChangePage=this.onChangePage.bind(this);

    }

    componentDidMount(){

        this.getcontentlist();
            }

    onChangePage(contents) {
        this.setState({ contents: contents });
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber});
      }

    getcontentlist()
    {
        axios.get(API_ROOT+"/gettype").then(result=>{
            console.log(result.data['msg'])
            this.setState({totalcount:result.data['msg'].length});
            let populatedcontentsvalues = result.data['msg'].map(result => { return result })
            this.setState({ contents: populatedcontentsvalues,exampleItems:populatedcontentsvalues });
        })
    }

    render(){


return(

    <div className="content">
<div className="row">
    <div style={{"textAlign":"right"}} className="col-md-12">
    <Link to={"/admin/modelcreate"}><button className="btn btn-primary">Create Model</button></Link>
    </div>
</div>
<br /><br />
        <table className="table table-bordered table-striped">
      <thead style={{"backgroundColor":"darkmagenta"}}>
        <tr>
            <th style={{"color":"white","paddingBottom":"10px"}}>S.no</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Model name</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Actions</th>
        </tr>
        </thead>
        <tbody>
        {this.state.contents.map((content,index) => <tr key={content.id}><td>{index+1}</td><td>{content.model_name}</td><td><Link to={"/admin/modeledit/"+content.id}><div class="btn btn-info btn-sm"><span class="glyphicon glyphicon-edit"></span></div></Link></td></tr>)}
       </tbody>
        </table>
        
        <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />        
        <br />
        <div>
       
      </div>
    


    </div>
)

    }


}

export default Modellist;