import React,{Component} from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from './../api-config';
import './../assets/css/loginform.css';
class Submodeledit extends Component {

    constructor(props){
        super(props);
        this.state = {
            contentid:this.props.match.params.id,types:[],alreadyexits:null
          }
    }
  getcontentsbyid()
    {
        const id={id:this.props.match.params.id};
        axios.post(API_ROOT+"/getsubmodelbyid",id).then(result=>{
        this.setState({submodelname:result.data['msg'][0].submodel_name,model:result.data['msg'][0].model_id
          });
        });
    }

    handlechange=(e)=>{

        if (e.target.id === 'submodelname') {
            this.setState({[e.target.id]: e.target.value});
            } else if(e.target.id === 'model') {
                this.setState({[e.target.id]: e.target.value});
                }
       }

       gettypes()
       {
           axios.get(API_ROOT+"/gettype").then(result=>{
               let populatedtypesvalues = result.data['msg'].map(result => { return {id: result.id, type_name: result.model_name} })
               this.setState({ types: populatedtypesvalues });
           })
   
       }


    componentDidMount() {
    this.getcontentsbyid();
    this.gettypes();
    }

    handlesubmit=(e)=>{
   e.preventDefault();
   
axios.post(API_ROOT+'/updatesubmodels',this.state).then(result=>{
this.props.history.push('/admin/submodellist');
})
    }

    render()
    {
return(
           <div className="content">
           <form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">



<div className="row">
  
<div className="col-md-6">
<div className="form-group">
    <label for="exampleInputEmail1">Model</label>
    <select value={this.state.model} required className="form-control" onChange={this.handlechange} id="model" >
        <option value="">Select Model</option>
        {this.state.types.map((cattypes) => <option key={cattypes.id} value={cattypes.id}>{cattypes.type_name}</option>)}
    </select>
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Sub model name</label>
    <input pattern=".{2,25}" title="2 to 25 Characters" required type="text" value={this.state.submodelname} onChange={this.handlechange} className="form-control" id="submodelname" placeholder="Enter submodel name"  />
        
  </div>
  </div>

  </div>


<div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
<span style={{"color":"red"}}>{this.state.alreadyexits} </span>&nbsp;&nbsp;
<NavLink
                      to="/admin/submodellist"
                      className="nav-link"
                      activeClassName="active"
                    > 
                    <button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;
                    <button type="submit" className="btn btn-primary">Update</button>
  </div>
  </div>


</div>
</div>
       </form>
      </div>

        )
    }

}

export default Submodeledit;