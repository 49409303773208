import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";

import logo from '../../assets/img/Oasys-Logo.png';

//import logo from "assets/img/reactlogo.png";

class Sidebar extends Component {
  constructor(props) {
    //let href="#";
    super(props);
    var sessionvar=JSON.parse(localStorage.getItem("token"));
   // console.log(sessionvar[0]['username']);
    this.state = {
      width: window.innerWidth,
      href:"#",
      username:sessionvar[0]['username'],
      role_id:sessionvar[0]['role_id']
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    //console.log(logo);
    
    /*const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };*/

    const sidebarBackground = {
      //backgroundImage: "url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd2oq7AaBvP7o_mHHKWWKk3XxkqwpDP9ikwOunYL8o5EjfstIW')"
      backgroundColor:"#692062"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )}
        <div style={{'background-color':'white'}} className="logo">
          <a
           href={this.state.href}
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </a>
          <a style={{'color':'black','fontSize':'23px'}}
            href={this.state.href}
            className="simple-text logo-normal"
          >
            OMS
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            
            <li class="">
              <NavLink
                      to="/admin/dashboard"
                      className="nav-link"
                      activeClassName="active"
                    > <i class="pe-7s-graph"></i><p>dashboard</p>
                    </NavLink>
            </li>


{this.state.role_id===1 ?

            <li>
					<a data-toggle="collapse" href="#componentsExamples" class="collapsed" aria-expanded="false">
                        <i class="pe-7s-plugin"></i>
                        <p>Master
                           <b class="caret"></b>
                        </p>
                    </a>

                    <div class="collapse" id="componentsExamples" aria-expanded="false" style={{"height": "0px;"}}>
						<ul class="nav">
						
            <li>
              <NavLink
                      to="/admin/modellist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Model</span>
								</NavLink>
							</li>

              <li>
              <NavLink
                      to="/admin/submodellist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Sub Model</span>
								</NavLink>
							</li>
  
              <li>
              <NavLink
                      to="/admin/documentcategorylist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Document Category</span>
								</NavLink>
							</li>

              <li>
              <NavLink
                      to="/admin/departmentlist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Department</span>
								</NavLink>
							</li>

              <li>
              <NavLink
                      to="/admin/processlist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Process</span>
								</NavLink>
							</li>
            	<li>
              <NavLink
                      to="/admin/contentlist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Artifacts</span>

								</NavLink>
							</li>
					
            
            
            	<li>
              <NavLink
                      to="/admin/userlist"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Users</span>
								</NavLink>
							</li>

             


              </ul>
              </div>
                    </li>

	: null }

                     <li>
					<a data-toggle="collapse" href="#componentsExamples2" class="collapsed" aria-expanded="false">
                        <i class="pe-7s-plugin"></i>
                        <p>Reports
                           <b class="caret"></b>
                        </p>
                    </a>

                    <div class="collapse" id="componentsExamples2" aria-expanded="false" style={{"height": "0px;"}}>
						<ul class="nav">
							<li>
              <NavLink
                      to="/admin/filterdocument"
                      className="nav-link"
                      activeClassName="active"
                    >
                
                    <span class="sidebar-mini"><i class="pe-7s-graph"></i></span>
									<span class="sidebar-normal">Artifacts</span>
								</NavLink>
							</li>
							
              </ul>
              </div>
                    </li>





            <li class="">
              <NavLink
                      to="/admin/flashmsg"
                      className="nav-link"
                      activeClassName="active"
                    > <i class="pe-7s-graph"></i><p>Flashmsg</p>
                    </NavLink>
            </li>

            <li class="">
              <NavLink
                      to="/api/logout"
                      className="nav-link"
                      activeClassName="active"
                    > <i class="pe-7s-graph"></i><p>Logout</p>
                    </NavLink>
            </li>


          
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
