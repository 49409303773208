import React, { Component } from "react";
import { Grid } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer style={{"position":"fixed","left":"0","bottom":"0","width":"100%"}} className="footer">
        <Grid fluid>
          <nav className="pull-left">
            <ul>
              
             
             
            </ul>
          </nav>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            <a href="http://www.oasys.co">
              OASYS Cybernetics pvt ltd
            </a>
            
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
