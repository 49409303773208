import React,{Component} from 'react';
import axios from 'axios';
import { API_ROOT } from './../api-config';
import readXlsxFile from 'read-excel-file';

class Xlupload extends Component
{

    constructor(props)
    {
        super(props);
        this.state={selectedFile:null,contents: [],contents1: []};
    }


     dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ia], {type:mimeString});
    }

    test(request)
    {
        var x;
        var formData = new FormData();  
        request.onload = function() {
            formData.append("imageFile", request.response);
            formData.append("author","user");
            formData.append("description","image");
            x = new XMLHttpRequest();
            x.open("POST",API_ROOT+"/upload",true);
            x.setRequestHeader("Content-type", "multipart/form-data");
            x.setRequestHeader("Content-Length", formData.length);
            x.send(formData);
          }
    }

    onChangeHandler=event=>{

                if(event.target.id === 'file') {
                    this.setState({[event.target.id]: event.target.files[0].name,selectedFile: event.target.files[0],loaded: 0,});
                }

                readXlsxFile(event.target.files[0]).then((rows) => {
                rows.forEach(function(item,index){


                    /*var blob = this.dataURItoBlob(item[6]);*/
                    //var fd = new FormData();
                    //fd.append("uploadedfiles", item[6].target.files[0]);


                     
                 //  var URL = item[6];    
                    

/*var request = new XMLHttpRequest();
request.responseType = "blob";
this.test(request);
request.open("GET", URL);
request.send();*/


var url = "/home/user/Downloads/pic.jpg";
//var url=item[6];
fetch(url)
.then(res => res.blob())
.then(blob => {
    console.log(blob);
  var fd = new FormData(document.getElementById('form1'))
  fd.append('uploadedfiles', blob)


  
  //console.log(fd)

  axios.post(API_ROOT+'/upload',fd).then(result=>{
             //    console.log(result);   
});

  
})

       //console.log(item[0]);//type
      //console.log(item[1]);//category
      //console.log(item[2]);//subcategory
      //console.log(item[3]);//department
      //console.log(item[4]);//content name
      //console.log(item[5]);//base line date
      //console.log(item[6]);//file path
      //console.log(item[7]);//Document id
      //console.log(item[8]);//Version no
      //console.log(item[9]);//Process id
                    
                    
                    });

        //console.log(rows);
                  })
        
            }


            handlesubmit=(e)=>{
                e.preventDefault();
                const data = new FormData() 
                data.append('uploadedfiles', this.state.selectedFile);
                axios.post(API_ROOT+'/upload',data).then(result=>{
                    
                    });

                    axios.post(API_ROOT+'/xlread',this.state).then(result=>{
                        //this.props.history.push('/admin/contentlist');
                        if(result.data['msg'])
                        {
                        let populatedcontentsvalues = result.data['msg'].map(result => { return result })
                        this.setState({ contents: populatedcontentsvalues,exampleItems:populatedcontentsvalues });
                        }
                        if(result.data['msg1'])
                        {

                            console.log(result);
                            let populatedcontentsvalues1 = result.data['msg1'].map(result => { return result })
                            this.setState({ contents1: populatedcontentsvalues1,exampleItems1:populatedcontentsvalues1 });
                           


                        }
                        

                        });

                        
                    }


    


    render()
    {


 return(

    <div className="content">
    <form id="form1" onSubmit={this.handlesubmit} method="post">
<div className="row">
<div className="col-md-4">
  <div className="form-group">
    <label for="exampleInputPassword1">Upload file</label>
    <input required onChange={this.onChangeHandler} type="file" className="form-control" id="file" placeholder="Select file" />
        
  </div>
  </div>
</div>

<div className="row">

    <div className="col-md-6">
      <input type="submit" name="sub" className="btn btn-primary" value="Submit" />
  </div>
</div>
</form>
<br />
<div className="row">
<ul className="list-group">
    {this.state.contents.map((content,index) => <li style={{"color":"red"}} className="list-group-item" key={content.index}>{content.orginalvalues} -- {content.row}</li>)}

    {this.state.contents1.map((content,index) => <li style={{"color":"red"}} className="list-group-item" key={content.index}>{content.alreadyexitsvalue} -- {content.row}</li>)}




</ul>
</div>
    </div>
 )


    }


}

export default Xlupload;
