import Dashboard from "views/Dashboard.jsx";
import Contentupload from "views/Contentupload.jsx";
import Contentuploadedit from "views/Contentuploadedit.jsx";
import Contentlist from "views/Contentlist.jsx";
import Filterdocument from "views/Filterdocument.jsx";
import Userlist from "views/Userlist.jsx";
import Adduser from "views/Adduser.jsx";
import Edituser from "views/Edituser.jsx";
import Xlupload from "views/Xlupload.jsx";
import Flashmsg from "views/Flashmsg.jsx";
import Model from "views/Model.jsx";
import Modellist from "views/Modellist.jsx";
import Modeledit from "views/Modeledit.jsx";
import Documentcategorycreate from "views/Documentcategorycreate.jsx";
import Documentcategorylist from "views/Documentcategorylist.jsx";
import Documentcategoryedit from "views/Documentcategoryedit.jsx";
import Departmentcreate from "views/Departmentcreate.jsx";
import Departmentlist from "views/Departmentlist.jsx";
import Departmentedit from "views/Departmentedit.jsx";
import Submodelcreate from "views/Submodelcreate.jsx";
import Submodellist from "views/Submodellist.jsx";
import Submodeledit from "views/Submodeledit.jsx";
import Processcreate from "views/Processcreate.jsx";
import Processlist from "views/Processlist.jsx";
import Processedit from "views/Processedit.jsx";

import Logout from "views/Logout.jsx";
import Test from "views/Test.jsx";
//import Testrouter from "views/Testrouter.jsx";
//import Logout from "views/Logout";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin"
  },

  {
    path: "/userlist",
    name: "List of Users",
    icon: "pe-7s-graph",
    component: Userlist,
    layout: "/admin"
  },
  {
    path: "/modelcreate",
    name: "Create Model",
    icon: "pe-7s-graph",
    component: Model,
    layout: "/admin"
  },
  {
    path: "/submodelcreate",
    name: "Create Sub Model",
    icon: "pe-7s-graph",
    component: Submodelcreate,
    layout: "/admin"
  },
  {
    path: "/processcreate",
    name: "Create Process",
    icon: "pe-7s-graph",
    component: Processcreate,
    layout: "/admin"
  },
  {
    path: "/processlist",
    name: "List of Process",
    icon: "pe-7s-graph",
    component: Processlist,
    layout: "/admin"
  },
  {
    path: "/processedit/:id",
    name: "Change Process",
    icon: "pe-7s-graph",
    component: Processedit,
    layout: "/admin"
  },
  {
    path: "/submodellist",
    name: "List of Sub Models",
    icon: "pe-7s-graph",
    component: Submodellist,
    layout: "/admin"
  },
  {
    path: "/submodeledit/:id",
    name: "Change Sub Model",
    icon: "pe-7s-graph",
    component: Submodeledit,
    layout: "/admin"
  },
  {
    path: "/documentcategorycreate",
    name: "Create Document Category",
    icon: "pe-7s-graph",
    component: Documentcategorycreate,
    layout: "/admin"
  },
  {
    path: "/departmentcreate",
    name: "Create Department",
    icon: "pe-7s-graph",
    component: Departmentcreate,
    layout: "/admin"
  },
  {
    path: "/departmentedit/:id",
    name: "Change Department",
    icon: "pe-7s-graph",
    component: Departmentedit,
    layout: "/admin"
  },
  {
    path: "/departmentlist",
    name: "List of Department",
    icon: "pe-7s-graph",
    component: Departmentlist,
    layout: "/admin"
  },
  {
    path: "/documentcategorylist",
    name: "List of Document Category",
    icon: "pe-7s-graph",
    component: Documentcategorylist,
    layout: "/admin"
  },
  {
    path: "/documentcategoryedit/:id",
    name: "Change Document Category",
    icon: "pe-7s-graph",
    component: Documentcategoryedit,
    layout: "/admin"
  },
  {
    path: "/modellist",
    name: "List of Models",
    icon: "pe-7s-graph",
    component: Modellist,
    layout: "/admin"
  },
  {
    path: "/modeledit/:id",
    name: "Change Model",
    icon: "pe-7s-graph",
    component: Modeledit,
    layout: "/admin"
  },
  {
    path: "/adduser",
    name: "Add User",
    icon: "pe-7s-graph",
    component: Adduser,
    layout: "/admin"
  },
  {
    path: "/xlupload",
    name: "Upload XL Sheet",
    icon: "pe-7s-graph",
    component: Xlupload,
    layout: "/admin"
  },
  {
    path: "/flashmsg",
    name: "Create Flash Message",
    icon: "pe-7s-graph",
    component: Flashmsg,
    layout: "/admin"
  },

  {
    path: "/edituser/:id",
    name: "Change User Details",
    icon: "pe-7s-graph",
    component: Edituser,
    layout: "/admin"
  },

  {
    path: "/contentupload",
    name: "Artifact Upload",
    icon: "pe-7s-graph",
    component: Contentupload,
    layout: "/admin"
  },

  {
    path: "/contentuploadedit/:id",
    name: "Change Artifact",
    icon: "pe-7s-graph",
    component: Contentuploadedit,
    layout: "/admin"
  },

  {
    path: "/contentlist",
    name: "List of Artifacts",
    icon: "pe-7s-graph",
    component: Contentlist,
    layout: "/admin",
  },

  {
    path: "/filterdocument",
    name: "Artifacts Download",
    icon: "pe-7s-graph",
    component: Filterdocument,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Log in",
    icon: "pe-7s-graph",
    component: Test,
    layout: "/api"
  },

 {
    path: "/logout",
    name: "Log out",
    icon: "pe-7s-graph",
    component: Logout,
    layout: "/api"
  },
 /* {
    path: "/user",
    name: "User Profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "pe-7s-science",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "pe-7s-map-marker",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications,
    layout: "/admin"
  }*/
  
];

export default dashboardRoutes;
