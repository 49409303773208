import React,{Component  } from "react";
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from './../api-config';
import './../assets/css/loginform.css';
class Model extends Component{

    constructor(props)
    {
        super(props);
        this.state={alreadyexits:null};
    }
    handlechange=(e)=>{

        if (e.target.id === 'modelname') {
            this.setState({[e.target.id]: e.target.value});
            } 

    }



    handlesubmit=(e)=>{
        e.preventDefault();

        axios.post(API_ROOT+'/checkmodel',this.state).then(result=>{
            console.log(result.data['msg'].length);
            if(result.data['msg'].length >=1)
            {
                this.setState({'alreadyexits':'Model is already Exists'});
            } else {

                axios.post(API_ROOT+'/postmodels',this.state).then(result=>{
                    this.props.history.push('/admin/modellist');
                    })      
            }
        })

        /*axios.post(API_ROOT+'/postmodels',this.state).then(result=>{
        this.props.history.push('/admin/modellist');
        })*/
        
            }


    render()
    {

        return(
<div className="content">
    
<form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">

      <div className="row">
<div className="col-md-6">
<div className="form-group">
    <label for="exampleInputEmail1">Model</label>
    <input pattern=".{2,25}" title="2 to 25 Characters" type="text" required placeholder="Enter model name" className="form-control" onChange={this.handlechange} id="modelname" />
  </div>
  </div>
</div>

<div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
    <span style={{"color":"red"}}>{this.state.alreadyexits} </span>&nbsp;&nbsp;
<NavLink
                      to="/admin/modellist"
                      className="nav-link"
                      activeClassName="active"
                    >
<button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;<button type="submit" className="btn btn-primary">Submit</button>
  </div>
  </div>

          </div>
          </div>
          </form>
          </div>
)
    }

}

export default Model;