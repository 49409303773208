import React,{Component} from 'react';
import { API_ROOT } from './../api-config';
import axios from 'axios';
import Pagination from './Pagination';
import {Link} from 'react-router-dom';

class Userlist extends Component{

constructor(props)
{
super(props);
this.state={contents:[]};
this.onChangePage=this.onChangePage.bind(this);
}


componentDidMount(){

    this.getcontentlist();
        }

        onChangePage(contents) {
            // update state with new page of items
            this.setState({ contents: contents });
        }

getcontentlist()
    {
        axios.get(API_ROOT+"/listofusers").then(result=>{
            //console.log(result.data['msg'].length)
            this.setState({totalcount:result.data['msg'].length});
            let populatedcontentsvalues = result.data['msg'].map(result => { 
               
                if(result.role_id===1)
                {
                    result['rolename']="Admin";
                }
                else {
                    result['rolename']="User";
                }
                
              //  console.log(result);
                return result;
             })
            this.setState({ contents: populatedcontentsvalues,exampleItems:populatedcontentsvalues });
        })
    }




render()
{

    return(
        

         <div className="content">



<div className="row">
    <div style={{"textAlign":"right"}} className="col-md-12">
    <Link to={"/admin/adduser"}><button className="btn btn-primary">Add User</button></Link>
    </div>
</div>
<br /><br />
        <table className="table table-bordered table-striped">
      <thead>
        <tr>
            <th>S.no</th>
            <th>Username</th>
            <th>Email</th>
            <th>Department</th>
            <th>Role</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {this.state.contents.map((content,index) => <tr key={content.id}><td>{index+1}</td><td>{content.username}</td><td>{content.email}</td><td>{content.dep_name}</td><td>{content.rolename}</td><td><Link to={"/admin/edituser/"+content.id}><button className="btn btn-primary">Edit</button></Link></td></tr>)}
        </tbody>
        </table>
        
        <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />        
        <br />
        <div>
       
      </div>
    

        
        
        </div>



    )
}


}

export default Userlist