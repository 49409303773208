import React,{Component} from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from './../api-config';
import './../assets/css/loginform.css';
class Edituser extends Component
{

    constructor(props)
    {
        super(props);
        this.state={departments:[],alreadyexits:null};
    }

    componentDidMount(){
        this.getdepartments();
        this.getuserbyid();
    }


    getuserbyid()
    {
        const id={id:this.props.match.params.id};
        axios.post(API_ROOT+"/getusersbyid",id).then(result=>{

      // console.log(result.data['msg'][0].baseline_date);

      //var myDate = new Date(result.data['msg'][0].baseline_date);
      //myDate.setDate(myDate.getDate()+1);
      

      //console.log(myDate.toISOString().split('T')[0]);
      //console.log(year+"-"+month+"-"+date);
       
       
       this.setState({username:result.data['msg'][0].username,
       email:result.data['msg'][0].email,department:result.data['msg'][0].dept_id,
       role:result.data['msg'][0].role_id,userid:result.data['msg'][0].id
       
    });
        });
    }


    getdepartments()
    {
        axios.get(API_ROOT+"/getdepartment").then(result=>{
            let populatedvalues = result.data['msg'].map(result => { return {id: result.id, department_name: result.dep_name} })
            this.setState({ departments: populatedvalues });
        })
    }


    handlechange=(e)=>{

        if (e.target.id === 'username') {
            this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'email') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'department') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'role') {
                this.setState({[e.target.id]: e.target.value});
            }
            
           
       }
    handlesubmit=(e)=>{
        e.preventDefault();
        
         axios.post(API_ROOT+'/updateuser',this.state).then(result=>{
             this.props.history.push('/admin/userlist');
             })
            }
        
    render()
    {

        return(

            <div className="content">
           <form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">



<div className="row">
   


  

  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Username</label>
    <input pattern=".{2,25}" title="2 to 25 Characters" value={this.state.username} required type="text" onChange={this.handlechange} className="form-control" id="username" placeholder="Enter user name"  />
        
  </div>
  </div>


  


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Email</label>
    <input pattern=".{2,30}" title="2 to 30 Characters" value={this.state.email} required type="email" onChange={this.handlechange} className="form-control" id="email" placeholder="Enter email"  />
        
  </div>
  </div>

  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Department</label>
    <select value={this.state.department} required className="form-control" onChange={this.handlechange} id="department" >
        
        <option value="">Select Department</option>
        {this.state.departments.map((dept) => <option key={dept.id} value={dept.id}>{dept.department_name}</option>)}
    </select>
  </div>
  </div>

   <div className="col-md-6">
<div className="form-group">
    <label for="exampleInputEmail1">Role</label>
    <select value={this.state.role} required className="form-control" onChange={this.handlechange} id="role" >
        <option value="">Select Role</option>
        <option value="2">User</option>
        <option value="1">Admin</option>
    </select>
  </div>
  </div>


 




  

  </div>



<div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
<span style={{"color":"red"}}>{this.state.alreadyexits} </span>&nbsp;&nbsp;
<NavLink
                      to="/admin/userlist"
                      className="nav-link"
                      activeClassName="active"
                    > 
                    <button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;
  <button type="submit" className="btn btn-primary">Update</button>
  </div>
  </div>


</div>
</div>
       </form>
      </div>
        )

    }

}


export default Edituser;