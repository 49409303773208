import React, { Component } from "react";
//import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { API_ROOT } from './../api-config';

//import CanvasJSReact from './../canvasjs.react';


import axios from 'axios';
//import PieChart from 'react-minimal-pie-chart';
import Highcharts from 'highcharts';

//import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
//import { Tasks } from "components/Tasks/Tasks.jsx";
//import { dataSales,  optionsSales, responsiveSales,legendSales,} from "variables/Variables.jsx";

class Dashboard extends Component {

  constructor(props){
    
    super(props);
    this.state={dashboardvalues:[],totalarray:[],latestcontents:[],latestflashmsgcontents:[]
      
    };
    
  }
  componentDidMount(){

    

    this.getdashboardcount();
    this.getlatestcontent();
    this.getlatestflashmsgcontents();
    this.highChartsRender();
  }


  

  getlatestflashmsgcontents()
  {
    

    axios.get(API_ROOT+"/getlatestflashmsg").then(result=>{
      //console.log(result);
      let populatedvalues = result.data['msg'].map(result => { return {id:result.id,message:result.message,startdate:result.start_date,enddate:result.end_date} })
      this.setState({ latestflashmsgcontents: populatedvalues });

    })
  }

  getlatestcontent()
  {
    axios.get(API_ROOT+"/getlatestcontent").then(result=>{

      console.log(result.data['msg']);
      this.setState({filename:result.data['msg'][0].filename,contentname:result.data['msg'][0].con_name,typename:result.data['msg'][0].type_name});



      let populatedvalues = result.data['msg'].map(result => { return {filename:result.filename,contentname:result.con_name,typename:result.type_name} })
      this.setState({ latestcontents: populatedvalues });


     /* let populatedlatestcontentvalues = result.data['msg'].map(result => {
        
        //console.log(total+result.type_count);
        //total+=parseInt(result.type_count);
       // this.setState({totalarray:result.type_count})

        return {filename: result.filename, contentname: result.con_name} });*/

        

    })
  }

  
  getdashboardcount()
  {
    
    axios.get(API_ROOT+"/getdocumentcount").then(result=>{
      
      let populatedcategoryvalues = result.data['msg'].map(result => {
        
        //console.log(total+result.type_count);
        //total+=parseInt(result.type_count);
       // this.setState({totalarray:result.type_count})

        return {type_name: result.type_name, type_count: result.type_count} })
      this.setState({ dashboardvalues: populatedcategoryvalues });

      //console.log(populatedcategoryvalues);
  })




  //console.log(this.state.dashboardvalues);

  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  highChartsRender() {
   
  }


  render() {
    //var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    //console.log(this.state.dashboardvalues.length);
    //console.log(this.state.dashboardvalues);
    var array=['#3498db','#9b59b6','#2ecc71','#ff3399','#ffccff'];
    //var colors = ['red', 'green', 'blue', 'orange', 'yellow'];
    var total=0;
    var length=this.state.dashboardvalues.length;
    var typenamearray=[];
    var typecountarray=[];
    var orginalobject=[];
    if(length!==0)
    {
      
    for(var i=0;i<length;i++)
    {
      typecountarray.push(this.state.dashboardvalues[i]['type_count']);
      typenamearray.push(this.state.dashboardvalues[i]['type_name']);
total=total+this.state.dashboardvalues[i]['type_count'];
orginalobject.push({"name":this.state.dashboardvalues[i]['type_name'],"y":this.state.dashboardvalues[i]['type_count'],"color":array[Math.floor(Math.random() * array.length)]});
    }

//console.log(JSON.parse(JSON.stringify(orginalobject)));
console.log(Math.floor(Math.random() * 255));


    Highcharts.chart({
      chart: {
        type: 'pie',
        renderTo: 'atmospheric-composition'
      },
      title: {
        verticalAlign: 'middle',
        floating: true,
        text: ' ',
        style: {
          fontSize: '10px',
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            format: '{point.name}-{point.y}'
          },
          innerSize: '0%'
        }
      },
      series: [{
        name: '',
          data:JSON.parse(JSON.stringify(orginalobject))
            
          
        }]
    });





  }

  


   // console.log(total);
    return (
      <div className="content">




        <Grid fluid>
          <Row>

            <Col  lg={3} sm={6}>
              <StatsCard 
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Total"
                statsValue={total}
                backgroundvalue="#00bbd3"
                
              />
</Col>

{this.state.dashboardvalues.map((catg,index) => 




<Col key={catg.index} lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText={catg.type_name}
                statsValue={catg.type_count}
                backgroundvalue={array[index]}
                
              />
</Col>
)}

           
          </Row>
          <Row>
            <Col md={7}>
            <div className="card" >
              
              <div style={{"fontWeight":"600","backgroundColor":"gainsboro","padding":"10px","textAlign":"center"}} className="card-header">
              Artifact Type Details
  </div>
  <div className="card-body" style={{marginLeft:"35px"}}>
<br />
<div className="row">
 
	

</div>


<div style={{"width":"400px"}} id="atmospheric-composition">
  	</div>


              </div></div>
               
              












            </Col>
            <Col md={5}  >
            <div className="card" >
              <div style={{"fontWeight":"600","backgroundColor":"gainsboro","padding":"10px","textAlign":"center"}} className="card-header">
    Latest Content
  </div>

  


  
  <div className="card-body" >
  <marquee scrolldelay="200" style={{"paddingBottom":"230px","font-size":"16px","font-wight":"bold","color":"mediumvioletred"}} direction="up">
    
  {this.state.latestcontents.map((dept) => <option key={dept.id} value={dept.id}>Content name :{dept.contentname} &nbsp;&nbsp;Model : {dept.typename}</option>)}

<br />
{this.state.latestflashmsgcontents.map((dept) => <option key={dept.id} value={dept.id}>Message :{dept.message} </option>)}
    
    </marquee>
  </div>
</div>
            </Col>



          </Row>

         
        </Grid>



      </div>
    ); 
  }
}

export default Dashboard;
