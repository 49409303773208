import React,{Component} from 'react';
import DatePicker from "react-datepicker";
import {NavLink} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { API_ROOT } from './../api-config';
import './../assets/css/loginform.css';
class Flashmsg extends Component
{
constructor(props)
{
    super(props);
    var sessionvar=JSON.parse(localStorage.getItem("token"));
    this.state={
        startdate:null,
        enddate:null,
        userid:sessionvar[0]['id']
    }
    this.handleChangedatepicker = this.handleChangedatepicker.bind(this);
    this.handleChangedatepicker1 = this.handleChangedatepicker1.bind(this);
}


handlesubmit=(e)=>{


    e.preventDefault();
    console.log(this.state);

    axios.post(API_ROOT+'/postflashmsg',this.state).then(result=>{

        if(result.status===200)
        {
            this.setState({successmesage:"Flash message has been created"});
        }
    })

}

handleChangedatepicker(date) {

    var month=date.getMonth()+1;
    this.setState({baselinedate1: date,startdate:date.getDate()+"-"+month+"-"+date.getFullYear()});
  }

  handleChangedatepicker1(date) {
    var month=date.getMonth()+1;
    this.setState({baselinedate2: date,enddate:date.getDate()+"-"+month+"-"+date.getFullYear()});
  }

handlechange=(e)=>{

    if (e.target.id === 'flashmessage') {
        this.setState({[e.target.id]: e.target.value});
        } 
        else if(e.target.id === 'documentid') {
            this.setState({[e.target.id]: e.target.value});
        }
        else if(e.target.id === 'versionno') {
            this.setState({[e.target.id]: e.target.value});
        }
       
       


}

render()
{

return(

      <div className="content">
           <form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">

      <div className="row">
      
      <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Message</label>
    <input pattern=".{2,100}" title="2 to 100 Characters" required type="text" onChange={this.handlechange} className="form-control" id="flashmessage" placeholder="Enter Message"  />
        
  </div>
  </div>


<div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">start date</label>
    <DatePicker
    selected={this.state.baselinedate1}
    onChange={this.handleChangedatepicker}
    id="startdate"
    className="form-control"
    dateFormat="dd-MM-yyyy"
    placeholderText="Start date"
    showMonthDropdown
    showYearDropdown
    autoComplete="off"
    maxDate={new Date()}
    

/>
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">End date</label>
    <DatePicker
    selected={this.state.baselinedate2}
    onChange={this.handleChangedatepicker1}
    id="enddate"
    className="form-control"
    dateFormat="dd-MM-yyyy"
    placeholderText="End date"
    showMonthDropdown
    showYearDropdown
    autoComplete="off"
    
    

/>
  </div>
  </div>

  <div style={{"color":"green","fontWeight":"bold"}} className="col-md-6">

{this.state.successmesage}
  </div>
  </div>

  <div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
<NavLink
                      to="/admin/dashboard"
                      className="nav-link"
                      activeClassName="active"
                    >
<button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;<button type="submit" className="btn btn-primary">Submit</button>
  </div>
  </div>

      
      
      </div>
      </div>
      </form>
      </div>
)

}


}

export default Flashmsg;