import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import './../assets/css/loginform.css';
import {API_ROOT} from './../api-config';



class Test extends Component {
    constructor(props) {
      super(props);
      this.state = { isloggedin:false };
      //this.handlePageChange = this._handlePageChange.bind(this);
    }
    handlechange=(e)=>{
        if (e.target.id === 'email') {
        this.setState({[e.target.id]: e.target.value});
        } else if(e.target.id === 'password') {
            this.setState({[e.target.id]: e.target.value});
        }
       // console.log(NODE_ENV_URL);
        //console.log(this.state);
    }

    handlesubmit=(e)=>{
        e.preventDefault();
        //const { email, password } = this.state;
       // console.log(JSON.stringify(this.state.email));
        /*axios.post('http://oasyscybernetics.com:9081/getuser',{'crossDomain': true}, { email,password })
        .then((result) => {
          console.log(result);
        });*/

        axios.post(API_ROOT+'/getuser', this.state)
        .then(res => {
           //console.log(res);
         if(res.data['msg'].length>=1)
         {   
            //console.log(res.data['msg']);
            localStorage.setItem("token",JSON.stringify(res.data['msg']));
            //this.props.history.push('/admin/dashboard');
            this.setState({isloggedin:true})
         } else {

            this.setState({
                invalid: 'Invalid Email & Password',
            });
   
         }
        }
            );


      /*  fetch('http://oasyscybernetics.com:9081/getuser', { method: 'POST',headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
      body: this.state,
    }).then((result)=>{
        
        if (result.ok) {
            
            result.json().then(json => {
              console.log(json['msg']);
            });
        }
    })*/


    }
    



    render(){
        if(this.state.isloggedin)
        {
            return <Redirect to="/admin/dashboard" />
        }
       if(localStorage.getItem("token")!==null)
        {
            return <Redirect to="/admin/dashboard" />
        }
       
        return(
<div className="login-form slideIn">
    <form onSubmit={this.handlesubmit} method="post">
        <h2 className="text-center">Log in</h2>       
        <div className="form-group">
            <input id="email" type="email" onChange={this.handlechange} className="form-control" placeholder="Email" required="required" />
        </div>
        <div className="form-group">
            <input id="password" type="password" onChange={this.handlechange} className="form-control" placeholder="Password" required="required" />
        </div>
        <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block">Log in</button>
        </div>
        <div className="clearfix">
            <label className="pull-left checkbox-inline"><input type="checkbox" /> Remember me</label>
            
        </div>
        <p className="text-danger">{this.state.invalid}</p>        
    </form>
    
    
</div>

        );
    }

}

export default Test;