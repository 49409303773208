import React,{Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { API_ROOT } from './../api-config';
import Pagination from './Pagination';
//import { TablePagination } from 'react-pagination-table';
//import './../assets/css/style.css';
//import Pagination from "react-js-pagination";

class Contentlist extends Component
{

    constructor(props)
    {
        super(props);
        this.state={contents: [],activePage: 1,
            categories: [],
            subcategories: [],
            types: [],
            category:null,
            subcategory:null,
            type:null,
            contentname:null,
            showComponent: false,
            noresultfound:null};
        this.onChangePage=this.onChangePage.bind(this);

    }
    componentDidMount(){

this.getcontentlist();
//this.getcategories();
this.getsubcategories();
this.gettypes();
    }

    handlechange=(e)=>{

        if (e.target.id === 'type') {
            this.setState({[e.target.id]: e.target.value});
            const id={id:e.target.value};
            this.getcategoriesbyid(id);
            } else if(e.target.id === 'category') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'subcategory') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'contentname') {
                this.setState({[e.target.id]: e.target.value});
            }
        
            


    }

    getcategoriesbyid(id)
    {
        axios.post(API_ROOT+"/getcategorybytype",id).then(result=>{
           // console.log(result);
            let populatedcategoryvalues = result.data['msg'].map(result => { 
                console.log(result)
                return {id: result.id, category_name: result.submodel_name} 
            })
            this.setState({ categories: populatedcategoryvalues });
        })
    }

    getcategories()
    {
        axios.get(API_ROOT+"/getcategory").then(result=>{
            let populatedcategoryvalues = result.data['msg'].map(result => { return {id: result.id, category_name: result.submodel_name} })
            this.setState({ categories: populatedcategoryvalues });
        })
    }


    getsubcategories()
    {
        axios.get(API_ROOT+"/getsubcategory").then(result=>{
            let populatedsubcategoryvalues = result.data['msg'].map(result => { return {id: result.id, subcategory_name: result.subcat_name} })
            this.setState({ subcategories: populatedsubcategoryvalues });
        })
    }

    gettypes()
    {
        axios.get(API_ROOT+"/gettype").then(result=>{
            let populatedtypesvalues = result.data['msg'].map(result => { return {id: result.id, type_name: result.model_name} })
            this.setState({ types: populatedtypesvalues });
        })

    }


    onChangePage(contents) {
        // update state with new page of items
        this.setState({ contents: contents });
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        //this.setState({activePage: pageNumber});
        this.setState({activePage: pageNumber});
      }

    getcontentlist()
    {
        axios.get(API_ROOT+"/getcontents").then(result=>{
            console.log(result.data['msg'])
            this.setState({totalcount:result.data['msg'].length});
            let populatedcontentsvalues = result.data['msg'].map(result => { return result })
            this.setState({ contents: populatedcontentsvalues,exampleItems:populatedcontentsvalues });
        })
    }


    filterdoc=(e)=>{
        e.preventDefault();
        var where="?filter=yes";

        if((this.state.type!=="") && (this.state.type!==null))
        {
            
            where+="&type="+this.state.type;
        }

        if((this.state.category!=="") && (this.state.category!==null))
        {
            
            where+="&category="+this.state.category;
        }

        if((this.state.subcategory!=="") && (this.state.subcategory!==null))
        {
            
            where+="&subcategory="+this.state.subcategory;
        }

        if((this.state.contentname!=="") && (this.state.contentname!==null))
        {
            
            where+="&contentname="+this.state.contentname;
        }

       /* this.setState({
            showComponent: true,
          });*/
        //  console.log(this.state.contentname);

        //  this.setState({"contentname": this.state.contentname});

        //  console.log(this.state);
        
        axios.get(API_ROOT+"/getcontents"+where).then(result=>{
            let populatedcontentsvalues = result.data['msg'].map(result => { return result })
            this.setState({ contents: populatedcontentsvalues,exampleItems:populatedcontentsvalues });
    
    });


    if(this.state.contents.length===0)
    {
  //  var noresultfound="No Result Found";

    this.setState({noresultfound:"No Result Found"});
    }

    
    }




    
    render(){

  // console.log(this.state.contents);

return(


    
    

    <div className="content">






 <form onSubmit={this.filterdoc} >
<div className="row">
<div className="col-md-4">
    <select required onChange={this.handlechange} id="type" className="form-control">
        <option value="">Select model</option>
        {this.state.types.map((cattypes) => <option key={cattypes.id} value={cattypes.id}>{cattypes.type_name}</option>)}
    </select>
</div>

<div className="col-md-4">
    <select onChange={this.handlechange} id="category" className="form-control">
        <option value="">Select Submodel</option>
        {this.state.categories.map((catg) => <option key={catg.id} value={catg.id}>{catg.category_name}</option>)}
    </select>
</div>

<div className="col-md-4">
    <select onChange={this.handlechange} id="subcategory" className="form-control">
        <option value="">Select Document Category</option>
        {this.state.subcategories.map((subcatg) => <option key={subcatg.id} value={subcatg.id}>{subcatg.subcategory_name}</option>)}
    </select>
</div>

</div>
<br />
<div className="row">
<div className="col-md-4">
    <input onChange={this.handlechange} type="text" id="contentname" className="form-control" placeholder="Enter Content name" />
</div>

<div className="col-md-4">
    <input type="submit" id="submit" className="btn btn-primary" value="Search" />
</div>
</div>
</form>
<div className="row">
    <div style={{"textAlign":"right"}} className="col-md-12">
    <Link to={"/admin/contentupload"}><button className="btn btn-primary">Upload Artifact</button></Link>
    </div>
</div>


<br />
        <table className="table table-bordered table-striped">
      <thead style={{"backgroundColor":"darkmagenta"}}>
        <tr>
            <th style={{"color":"white","paddingBottom":"10px"}}>S.no</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Model name</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Sub model name</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Document Category</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Department name</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Content name</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Document ID</th>
            <th style={{"color":"white","paddingBottom":"10px"}}>Actions</th>
        </tr>
        </thead>
        <tbody>
        {this.state.contents.map((content,index) => <tr key={content.id}><td>{index+1}</td><td>{content.model_name}</td><td>{content.submodel_name}</td><td>{content.document_category}</td><td>{content.dep_name}</td><td>{content.con_name}</td><td>{content.doc_id}</td><td><Link to={"/admin/contentuploadedit/"+content.id}><div class="btn btn-info btn-sm"><span class="glyphicon glyphicon-edit"></span></div></Link></td></tr>)}

         

        </tbody>
        </table>
        
        <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />        
        <br />
        <div>
       
      </div>
    

        
        
        </div>


)

    }

}
export default Contentlist;