import React from "react";
import ReactDOM from "react-dom";


import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

//import "./assets/sass/lbd/_sidebar-and-main-panel.scss";
//import "./assets/sass/lbd/_navbars.scss";

import AdminLayout from "layouts/Admin.jsx";
import Loginlayout from "layouts/Loginlayout.jsx";

//console.log(API_ROOT);


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/api" render={props => <Loginlayout {...props} />} />


      <Redirect from="/" to="/api/login" />
      
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
