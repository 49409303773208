import React, { Component } from "react";

//import {Redirect} from 'react-router-dom';

class Logout extends Component {
    constructor(props) {
      super(props);
      this.state = { };
      localStorage.removeItem("token");
      this.props.history.push('/api/login');
    }
    render(){
//console(this.props.loggedin);
return(

  <div>sd</div>
)



    }
}

export default Logout;