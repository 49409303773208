import React,{Component} from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from './../api-config';
import './../assets/css/loginform.css';
class Modeleditedit extends Component {

    constructor(props){
        super(props);
        this.state = {
            contentid:this.props.match.params.id,
            alreadyexits:null
          }
    }
  getcontentsbyid()
    {
        const id={id:this.props.match.params.id};
        axios.post(API_ROOT+"/getmodelsbyid",id).then(result=>{
        this.setState({modelname:result.data['msg'][0].model_name
          });
        });
    }

    handlechange=(e)=>{

        if (e.target.id === 'modelname') {
            this.setState({[e.target.id]: e.target.value});
            } 
       }

     


    componentDidMount() {
    this.getcontentsbyid();
    }

    handlesubmit=(e)=>{
   e.preventDefault();

   
axios.post(API_ROOT+'/updatemodels',this.state).then(result=>{
this.props.history.push('/admin/modellist');
})
    
    }

    render()
    {
return(
           <div className="content">
           <form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">



<div className="row">
  
  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Model Name</label>
    <input pattern=".{2,25}" title="2 to 25 Characters" required type="text" value={this.state.modelname} onChange={this.handlechange} className="form-control" id="modelname" placeholder="Enter model name"  />
        
  </div>
  </div>

  </div>


<div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
<span style={{"color":"red"}}>{this.state.alreadyexits} </span>&nbsp;&nbsp;
<NavLink
                      to="/admin/modellist"
                      className="nav-link"
                      activeClassName="active"
                    > 
                    <button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;
                    <button type="submit" className="btn btn-primary">Update</button>
  </div>
  </div>


</div>
</div>
       </form>
      </div>

        )
    }

}

export default Modeleditedit;