import React,{Component} from 'react';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import { API_ROOT } from './../api-config';
import './../assets/css/loginform.css';
class Adduser extends Component
{

    constructor(props)
    {
    super(props);
    this.state={departments: [],alreadyexits:null};
    }

    componentDidMount(){
        this.getdepartments();
    }

    getdepartments()
    {
        axios.get(API_ROOT+"/getdepartment").then(result=>{
            let populatedvalues = result.data['msg'].map(result => { return {id: result.id, department_name: result.dep_name} })
            this.setState({ departments: populatedvalues });
        })
    }


    handlechange=(e)=>{

        if (e.target.id === 'username') {
            this.setState({[e.target.id]: e.target.value});
            } else if(e.target.id === 'password') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'email') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'department') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'role') {
                this.setState({[e.target.id]: e.target.value});
            }
            
           
       }



       handlesubmit=(e)=>{
        e.preventDefault();
        
        axios.post(API_ROOT+'/checkusers',this.state).then(result=>{
            if(result.data['msg'].length >=1)
            {
                this.setState({'alreadyexits':'User already Exists'});
            } else {
         axios.post(API_ROOT+'/postusers',this.state).then(result=>{
             this.props.history.push('/admin/userlist');
             })
            }
        })
        
     
         }



    render(){



        return(

<div className="content">
           <form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">



<div className="row">
   


  

  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Username</label>
    <input pattern=".{2,25}" title="2 to 25 Characters" required type="text" onChange={this.handlechange} className="form-control" id="username" placeholder="Enter user name"  />
        
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input pattern=".{2,15}" title="2 to 15 Characters" required type="password" onChange={this.handlechange} className="form-control" id="password" placeholder="Enter password"  />
        
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Email</label>
    <input pattern=".{2,30}" title="2 to 30 Characters" required type="email" onChange={this.handlechange} className="form-control" id="email" placeholder="Enter email"  />
        
  </div>
  </div>

  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Department</label>
    <select required className="form-control" onChange={this.handlechange} id="department" >
        
        <option value="">Select Department</option>
        {this.state.departments.map((dept) => <option key={dept.id} value={dept.id}>{dept.department_name}</option>)}
    </select>
  </div>
  </div>

   <div className="col-md-6">
<div className="form-group">
    <label for="exampleInputEmail1">Role</label>
    <select required className="form-control" onChange={this.handlechange} id="role" >
        <option value="">Select Role</option>
        <option value="2">User</option>
        <option value="1">Admin</option>
    </select>
  </div>
  </div>


 




  

  </div>



<div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
<span style={{"color":"red"}}>{this.state.alreadyexits} </span>&nbsp;&nbsp;
<NavLink
                      to="/admin/userlist"
                      className="nav-link"
                      activeClassName="active"
                    > 
                    <button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;
  <button type="submit" className="btn btn-primary">Submit</button>
  </div>
  </div>


</div>
</div>
       </form>
      </div>


        )
    }

}

export default Adduser;