import React,{Component} from 'react';
import DatePicker from "react-datepicker";
import {NavLink} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import './../assets/css/loginform.css';
//import {Grid,Row,Col} from "react-bootstrap";
  //import { Card } from "components/Card/Card.jsx";
//import { FormInputs } from "components/FormInputs/FormInputs.jsx";
//import { UserCard } from "components/UserCard/UserCard.jsx";
//import Button from "components/CustomButton/CustomButton.jsx";
//import avatar from "assets/img/faces/face-3.jpg";

import axios from 'axios';
import { API_ROOT } from './../api-config';
//import { formatDistanceToNow } from 'date-fns';

class Conntentupload extends Component {

    constructor(props){
        super(props);
        var sessionvar=JSON.parse(localStorage.getItem("token"));
        //console.log(sessionvar);
        //console.log(sessionvar[0]['id']);
        //this.state={};

        this.state = {
            processes:[],
            departments: [],
            categories: [],
            subcategories: [],
            types: [],
            selectedFile:null,
            baselinedate:null,
            userid:sessionvar[0]['id']
          }
          this.handleChangedatepicker = this.handleChangedatepicker.bind(this);
          
          
    }

    getdepartments()
    {
        axios.get(API_ROOT+"/getdepartment").then(result=>{
            let populatedvalues = result.data['msg'].map(result => { return {id: result.id, department_name: result.dep_name} })
            this.setState({ departments: populatedvalues });
        })
    }

    getprocess()
    {
        axios.get(API_ROOT+"/getprocess").then(result=>{
            let populatedvalues = result.data['msg'].map(result => { return {id: result.id, process_name: result.process_name} })
            this.setState({ processes: populatedvalues });
        })
    }

    getcategories()
    {
        axios.get(API_ROOT+"/getcategory").then(result=>{
            let populatedcategoryvalues = result.data['msg'].map(result => { return {id: result.id, category_name: result.submodel_name} })
            this.setState({ categories: populatedcategoryvalues });
        })
    }

    getcategoriesbyid(id)
    {
        axios.post(API_ROOT+"/getcategorybytype",id).then(result=>{
           // console.log(result);
            let populatedcategoryvalues = result.data['msg'].map(result => { 
                console.log(result)
                return {id: result.id, category_name: result.submodel_name} 
            })
            this.setState({ categories: populatedcategoryvalues });
        })
    }


    getsubcategories()
    {
        axios.get(API_ROOT+"/getsubcategory").then(result=>{
            let populatedsubcategoryvalues = result.data['msg'].map(result => { return {id: result.id, subcategory_name: result.subcat_name} })
            this.setState({ subcategories: populatedsubcategoryvalues });
        })
    }

    gettypes()
    {
        axios.get(API_ROOT+"/gettype").then(result=>{
            let populatedtypesvalues = result.data['msg'].map(result => { return {id: result.id, type_name: result.model_name} })
            this.setState({ types: populatedtypesvalues });
        })

    }

    handleChangedatepicker(date) {
        var month=date.getMonth()+1;
        this.setState({baselinedate1: date,baselinedate:date.getDate()+"-"+month+"-"+date.getFullYear()});
      }

    handlechange=(e)=>{

        if (e.target.id === 'type') {
            this.setState({[e.target.id]: e.target.value});
            //alert(e.target.value);
            const id={id:e.target.value};
            this.getcategoriesbyid(id);
            } else if(e.target.id === 'submodel') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'documentcategory') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'department') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'process') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'contentname') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'documentid') {
                this.setState({[e.target.id]: e.target.value});
            }
            else if(e.target.id === 'versionno') {
                this.setState({[e.target.id]: e.target.value});
            }
           
           


    }


    onChangeHandler=event=>{

//console.log(event.target.files[0]);
        if(event.target.id === 'file') {
            this.setState({[event.target.id]: event.target.files[0].name,selectedFile: event.target.files[0],loaded: 0,});
        }

       

    }



    componentDidMount() {
    this.getdepartments();
    //this.getcategories();
    this.getsubcategories();
    this.gettypes();
    this.getprocess();
    }

    handlesubmit=(e)=>{


e.preventDefault();
//console.log(this.state);

const data = new FormData() 
data.append('uploadedfiles', this.state.selectedFile);

if((this.state.selectedFile!=="")&&(this.state.selectedFile!==null))
{

    axios.post(API_ROOT+'/upload',data).then(result=>{
        });

    axios.post(API_ROOT+'/postcontent',this.state).then(result=>{
this.props.history.push('/admin/contentlist');
});
    
}
else {
    
}


/*axios.post('http://oasyscybernetics.com:9081/postcontent',this.state).then(result=>{
this.props.history.push('/admin/contentlist');
})*/

    }

    render()
    {
        return(
           <div className="content">
           <form onSubmit={this.handlesubmit} method="post">
<div style={{"border":"1px solid #e5e5e5"}} className="card">
  <div style={{"margin":"7px"}} className="card-body">



<div className="row">
    <div className="col-md-6">
<div className="form-group">
    <label for="exampleInputEmail1">Model</label>
    <select required className="form-control" onChange={this.handlechange} id="type" >
        <option value="">Select Model</option>
        {this.state.types.map((cattypes) => <option key={cattypes.id} value={cattypes.id}>{cattypes.type_name}</option>)}
    </select>
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Sub model</label>
    <select required className="form-control" onChange={this.handlechange} id="submodel" >
        <option value="">Select Submodel</option>

        {this.state.categories.map((catg) => <option key={catg.id} value={catg.id}>{catg.category_name}</option>)}
    </select>
  </div>
  </div>

<div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Document Category</label>
    <select required className="form-control" onChange={this.handlechange} id="documentcategory" >
        <option value="">Select Document Category</option>
        {this.state.subcategories.map((subcatg) => <option key={subcatg.id} value={subcatg.id}>{subcatg.subcategory_name}</option>)}
    </select>
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Department</label>
    <select required className="form-control" onChange={this.handlechange} id="department" >
        
        <option value="">Select Department</option>
        {this.state.departments.map((dept) => <option key={dept.id} value={dept.id}>{dept.department_name}</option>)}
    </select>
  </div>
  </div>


<div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Process</label>
    <select required className="form-control" onChange={this.handlechange} id="process" >
        
        <option value="">Select Process</option>
        {this.state.processes.map((dept) => <option key={dept.id} value={dept.id}>{dept.process_name}</option>)}
    </select>
  </div>
  </div>


<div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Document ID</label>
    <input pattern=".{2,50}" title="2 to 50 Characters" required type="text" onChange={this.handlechange} className="form-control" id="documentid" placeholder="Enter Document ID"  />
        
  </div>
  </div>

  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Document Name</label>
    <input pattern=".{2,50}" title="2 to 50 Characters" required type="text" onChange={this.handlechange} className="form-control" id="contentname" placeholder="Enter content name"  />
        
  </div>
  </div>

<div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Version No</label>
    <input required type="text" onChange={this.handlechange} className="form-control" id="versionno" placeholder="Enter Version no"  />
        
  </div>
  </div>


  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Base Line Date</label>
    <DatePicker
    selected={this.state.baselinedate1}
    onChange={this.handleChangedatepicker}
    id="baselinedate"
    className="form-control"
    dateFormat="dd-MM-yyyy"
    placeholderText="Base line date"
    showMonthDropdown
    showYearDropdown
    autoComplete="off"
    maxDate={new Date()}
    

/>
  </div>
  </div>







  <div className="col-md-6">
  <div className="form-group">
    <label for="exampleInputPassword1">Browse file</label>
    <input required onChange={this.onChangeHandler} type="file" className="form-control" id="file" placeholder="Select file" />
        
  </div>
  </div>

  </div>



<div className="row">
<div style={{"textAlign":"right"}} className="col-md-12">
<NavLink
                      to="/admin/contentlist"
                      className="nav-link"
                      activeClassName="active"
                    >
<button type="button" className="btn btn-primary">Back</button>
                    </NavLink>&nbsp;<button type="submit" className="btn btn-primary">Submit</button>
  </div>
  </div>


</div>
</div>
       </form>
      </div>

        )
    }

}

export default Conntentupload;